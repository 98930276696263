<template>
  <div class="container-fluid">
    <div class="row">
      <layout-navigation/>
      <div class="col-lg-12 col-content">
        <div class="content">
          <h5 class="router-text">Resumo</h5>
          <div class="container-fluid mt-3">
            <div class="row pl-0 pr-0">
              <div class="col-lg-7 pl-0 pr-3">
                <div class="container-fluid mb-3">
                  <div class="row info-container">
                    <div>
                      <template v-if="user && user.name">
                        <h5>Olá, Sr. {{ this.capitalize(user.name) }}</h5>
                      </template>
                      <template v-else>
                        <h5>Olá, </h5>
                      </template>
                      <p class="mt-3">
                        Verifique como está o andamento de nossa missão.
                      </p>
                      <div class="row pl-3">
                        <button class="btn btn-primary btn-sm mr-2" @click="$router.push({ name: 'customers' })">
                            Verificar nossos clientes
                        </button>
                        <button class="btn btn-secondary btn-sm" @click="$router.push({ name: 'affiliates' })">
                            Verificar afiliados
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 pl-0 pr-0">
                <div class="container-fluid mb-3">
                  <div class="row info-container warning-card">
                    <div>
                      <h4>
                        Nossa Missão
                      </h4>
                      <p class="mt-3">
                        Nenhum contador ou funcionário de uma contabilidade deve realizar o serviço que um robô possa fazer.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pl-0 pr-0">
              <div class="col-lg-3 pl-0 pr-3">
                <div class="card card-info">
                  <div class="row justify-content-between" v-if="!loadingPage">
                    <div class="pl-3">
                      <h4 class="text-header">Total de Clientes</h4>
                      <h5 class="mt-3 subtitle">{{users.length}} Cadastrados</h5>
                    </div>
                    <div class="pr-3 icon-plan">
                      <i class="fas fa-building"></i>
                    </div>
                    <div class="row-fluid w-100">
                      <button class="btn btn-primary btn-sm mt-1 ml-3" @click="$router.push({ name: 'customers' })">
                          Adicionar clientes
                      </button>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div>
                      <div class="title-loading"></div>
                      <div class="subtitle-loading"></div>
                      <div class="btn-loading"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 pl-0 pr-3">
                <div class="card card-info">
                  <div class="row justify-content-between" v-if="!loadingPage">
                    <div class="pl-3">
                      <h4 class="text-header">Total de Afiliados</h4>
                      <h5 class="mt-3 subtitle">{{affiliates.length}} Ativos</h5>
                    </div>
                    <div class="pr-4 icon-plan">
                      <i class="fas fa-users"></i>
                    </div>
                    <div class="row-fluid w-100">
                      <button class="btn btn-primary btn-sm mt-1 ml-3" @click="$router.push({ name: 'customers' })">
                          Adicionar afiliados
                      </button>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div>
                      <div class="title-loading"></div>
                      <div class="subtitle-loading"></div>
                      <div class="btn-loading"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 pl-0 pr-3">
                <div class="card card-info">
                  <div class="row justify-content-between" v-if="!loadingPage">
                    <div class="pl-3">
                      <h4 class="text-header pr-3">Total de Assinantes</h4>
                      <h5 class="mt-3 subtitle">P:{{totalPaid}} | A:{{totalUnpaid}} | Z:{{totalZero}} | T:{{totalCustomers}}</h5>
                    </div>
                    <div class="pr-3 icon-plan">
                      <i class="fas fa-cash-register"></i>
                    </div>
                    <div class="row-fluid w-100">
                      <button class="btn btn-primary btn-sm mt-1 ml-3" @click="$router.push({ name: 'users' })">
                          Verificar assinaturas
                      </button>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div>
                      <div class="title-loading"></div>
                      <div class="subtitle-loading"></div>
                      <div class="btn-loading"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 pl-0 pr-0">
                <div class="card card-info">
                  <div class="row justify-content-between" v-if="!loadingPage">
                    <div class="pl-3">
                      <h4 class="text-header">Assinaturas Canceladas</h4>
                      <h5 class="mt-3 subtitle">{{totalCanceled}} Assinaturas</h5>
                    </div>
                    <div class="pr-3 icon-plan">
                      <i class="fas fa-exclamation-circle"></i>
                    </div>
                    <div class="row-fluid w-100">
                      <button class="btn btn-primary btn-sm mt-1 ml-3" @click="$router.push({ name: 'users' })">
                          Verificar Clientes
                      </button>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div>
                      <div class="title-loading"></div>
                      <div class="subtitle-loading"></div>
                      <div class="btn-loading"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-7 pr-3 pl-0">
                <div class="container-fluid card card-chart h-100">
                  <h4>Últimos Faturamentos</h4>
                  <template>
                    <div class="row-fluid h-100">
                      <apexchart height="100%" type="bar" :options="chartOptions" :series="series"></apexchart>
                    </div>
                  </template>
                </div>
              </div>
              <div class="col-lg-5 pl-0 pr-0 table-card card">
                <div class="container-fluid mt-3">
                  <h4 class="mt-2 mb-4">Afiliados</h4>
                  <div class="row search-container">
                    <div class="col-12 pl-0">
                      <input
                        type="name"
                        v-model="searchValue"
                        class="form-control"
                        placeholder="Digite quem deseja buscar..."
                        required
                      >
                    </div>
                  </div>
                </div>
                <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Nome</th>
                        <th scope="col">Clientes</th>
                        <th scope="col">Assinantes</th>
                        <th scope="col">Faturamento</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        :key="i"
                        v-for="(affiliate, i) in filteredAffiliates"

                      >
                      <template v-if="affiliate.actived">
                        <template v-if="affiliate.name.length && affiliate.name.length > 20">
                          <td>{{affiliate.name.substring(0, 17)}}...</td>
                        </template>
                        <template v-else-if="affiliate.name.length === 0">
                          <td>Buscando...</td>
                        </template>
                        <template v-else>
                          <td>{{affiliate.name}}</td>
                          <td v-if="affiliate.affiliateCustomers">{{affiliate.affiliateCustomers}}</td><td v-else>0</td>
                          <td v-if="affiliate.affiliateSubscribers">{{affiliate.affiliateSubscribers}}</td><td v-else>0</td>
                          <td v-if="affiliate.affiliateBilling">R$ {{affiliate.affiliateBilling}}</td><td v-else>0</td>
                        </template>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" :class="{show: showTutorial}" :style="{display: showTutorial ? 'block' : 'none'}">
      <div class=" modal-dialog modal-dialog-centered modal-lg" role="dialog" style="transform: translate(0, -40px); z-index: 9999;">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <button type="button" class="close" @click="showTutorial = false; pauseVideo()" data-bs-dismiss="modal" aria-label="Close">
              <i class="fas fa-times" style="color: white;"></i>
            </button>
          </div>
          <div class="row justify-content-center player">
            <youtube player-width="800" player-height="450" :video-id="'HzU5zVX17no'" ref="youtube"></youtube>
          </div>
        </div>
      </div>
      <div class="modal-backdrop fade" :class="{show: showTutorial}" @click="showTutorial = false; pauseVideo()" :style="{display: showTutorial ? 'block' : 'none'}"></div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import LayoutNavigation from '../../components/layout/LayoutNavigation'

export default {
  name: 'Home',
  components: {
    LayoutNavigation
  },
  data: () => {
    return {
      showTutorial: false,
      subscription: null,
      subscriptions: [],
      user: {},
      plans: [],
      userPlan: {},
      users: [],
      affiliates: [],
      searchValue: '',
      totalGuides: 0,
      loadingPage: true,
      totalPaid: 0,
      totalZero: 0,
      totalUnpaid: 0,
      totalCanceled: 0,
      totalCustomers: 0,
      series: [{
        name: 'Emissões',
        data: []
      }],
      chartOptions: {
        colors: ['#068373'],
        chart: {
          height: 350,
          type: 'bar',
          events: {},
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            columnWidth: '27px',
            distributed: false,
            borderRadius: 10,
            colors: {
              ranges: [{
                from: 0,
                to: 0,
                color: '#068373'
              }],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 1
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: '#068373',
              fontSize: '12px'
            }
          }
        }
      }
    }
  },
  async created () {
    await this.getUsers()
    await this.getUser()
    await this.getSubscriptions()
    await this.getAffiliates()
    setTimeout(() => {
      this.verifyPayments()
      this.affiliateInfos()
    }, 2000)
  },
  computed: {
    filteredAffiliates () {
      let tempAffiliates = this.affiliates

      if (this.searchValue !== '' && this.searchValue) {
        tempAffiliates = tempAffiliates.filter((item) => {
          return item.cnpj
            .toUpperCase()
            .includes(this.searchValue.toUpperCase()) + item.name
            .toUpperCase()
            .includes(this.searchValue.toUpperCase())
        })
      }

      tempAffiliates = tempAffiliates.sort((a, b) => {
        if (this.sortBy === 'alphabetically') {
          const fa = a.name.toLowerCase()
          const fb = b.name.toLowerCase()
          if (fa < fb) {
            return -1
          }
          if (fa > fb) {
            return 1
          }
          return 0
        }
      })

      return tempAffiliates
    }
  },
  methods: {
    capitalize (data) {
      var capitalized = []
      var dataTemp = data + ''
      dataTemp.split(' ').forEach(word => {
        capitalized.push(
          word.charAt(0).toUpperCase() +
          word.slice(1).toLowerCase()
        )
      })
      return capitalized.join(' ')
    },
    async getPlans () {
      await this.$firebase.database().ref('plans').once('value', snapshot => {
        this.plans = snapshot.val()
      })
    },
    async getUsers () {
      await this.$firebase.database().ref('users').once('value', snapshot => {
        const values = snapshot.val()
        if (values) {
          this.users = Object.keys(values).map(i => values[i])
        }
      })
    },
    async verifyPayments () {
      var totalCustomers = 0
      var totalPaid = 0
      var totalZero = 0
      var totalUnpaid = 0
      var totalCanceled = 0
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].currentSubscriptionId) {
          for (let z = 0; z < this.subscriptions.length; z++) {
            if (this.subscriptions[z].id === this.users[i].currentSubscriptionId) {
              if (this.subscriptions[z].status !== 'canceled') {
                totalCustomers += 1
              }
              if (this.subscriptions[z].status === 'paid') {
                totalPaid += 1
              } else if (this.subscriptions[z].status === 'canceled') {
                totalCanceled += 1
              } else if ((this.subscriptions[z].status !== 'canceled' || this.subscriptions[z].status !== 'paid') && parseInt(this.users[i].emissions) === 0) {
                totalZero += 1
              } else {
                totalUnpaid += 1
              }
            }
          }
        }
      }
      this.totalCustomers = totalCustomers
      this.totalZero = totalZero
      this.totalPaid = totalPaid
      this.totalUnpaid = totalUnpaid
      this.totalCanceled = totalCanceled
    },
    affiliateBilling (affiliateId) {
      let totalBilling = 0
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].affiliateId === affiliateId) {
          for (let z = 0; z < this.subscriptions.length; z++) {
            if (this.subscriptions[z].id === this.users[i].currentSubscriptionId) {
              if (this.subscriptions[z].status === 'paid') {
                totalBilling += this.subscriptions[z].plan.amount
              }
            }
          }
        }
      }
      return (totalBilling / 100).toFixed(2)
    },
    affiliateSubscribers (affiliateId) {
      let totalSubscribers = 0
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].affiliateId === affiliateId) {
          for (let z = 0; z < this.subscriptions.length; z++) {
            if (this.subscriptions[z].id === this.users[i].currentSubscriptionId) {
              if (this.subscriptions[z].status === 'paid') {
                totalSubscribers += 1
              } else if (this.subscriptions[z].status === 'canceled') {
                totalSubscribers += 1
              } else {
                totalSubscribers += 1
              }
            }
          }
        }
      }
      return totalSubscribers
    },
    affiliateCustomers (affiliateId) {
      let totalCustomers = 0
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].affiliateId === affiliateId) {
          totalCustomers += 1
        }
      }
      return totalCustomers
    },
    async getSubscriptions () {
      await this.$firebase.database().ref('apiKeys').child('pagarmeApiKey').once('value', snapshot => {
        this.$pagarme.client.connect({ api_key: snapshot.val() }).then(client => client.subscriptions.all({ count: 1000 })).then(subscription => {
          this.subscriptions = subscription
        })
      })
    },
    async getUser () {
      await this.$firebase.database().ref(`admins/${window.uid}`).on('value', snapshot => {
        this.user = snapshot.val()
      })
    },
    async getAffiliates () {
      await this.$firebase.database().ref('affiliates').on('value', snapshot => {
        const values = snapshot.val()
        if (values) {
          this.affiliates = Object.keys(values).map(i => values[i])
        }
      })
    },
    affiliateInfos () {
      for (let i = 0; i < this.affiliates.length; i++) {
        this.affiliates[i].affiliateBilling = this.affiliateBilling(this.affiliates[i].affiliateId)
        this.affiliates[i].affiliateSubscribers = this.affiliateSubscribers(this.affiliates[i].affiliateId)
        this.affiliates[i].affiliateCustomers = this.affiliateCustomers(this.affiliates[i].affiliateId)
      }
      this.loadingPage = false
    },
    organizeChart () {
      const data = []
      const categories = []
      this.totalGuides = 0
      const historicTemp = Object.keys(this.user.emissionsHistoric).map((key) => {
        return this.user.emissionsHistoric[key]
      })

      this.$delete(this.user, 'emissionsHistoric')
      Vue.set(this.user, 'emissionsHistoric', historicTemp)
      for (let i = 0; i < this.user.emissionsHistoric.length; i++) {
        const emissionsTemp = Object.keys(this.user.emissionsHistoric[i].emissions).map((key) => {
          return this.user.emissionsHistoric[i].emissions[key]
        })
        this.totalGuides = this.totalGuides + emissionsTemp.length
      }
      if (this.user.emissionsHistoric.length > 5) {
        for (let i = 0; i < this.user.emissionsHistoric.length; i++) {
          const emissionsTemp = Object.keys(this.user.emissionsHistoric[i].emissions).map((key) => {
            return this.user.emissionsHistoric[i].emissions[key]
          })
          if (i > this.user.emissionsHistoric.length - 7) {
            this.$delete(this.user.emissionsHistoric[i], 'emissions')
            Vue.set(this.user.emissionsHistoric[i], 'emissions', emissionsTemp)
            data.push(this.user.emissionsHistoric[i].emissions.length)
            categories.push(this.user.emissionsHistoric[i].month)
            this.chartOptions.xaxis.categories.push(this.user.emissionsHistoric[i].month)
          }
        }
      } else {
        for (let i = 0; i < 6; i++) {
          if (this.user.emissionsHistoric[i] && this.user.emissionsHistoric[i].emissions) {
            const emissionsTemp = Object.keys(this.user.emissionsHistoric[i].emissions).map((key) => {
              return this.user.emissionsHistoric[i].emissions[key]
            })
            this.$delete(this.user.emissionsHistoric[i], 'emissions')
            Vue.set(this.user.emissionsHistoric[i], 'emissions', emissionsTemp)
            data.push(this.user.emissionsHistoric[i].emissions.length)
            categories.push(this.user.emissionsHistoric[i].month)
            this.chartOptions.xaxis.categories.push(this.user.emissionsHistoric[i].month)
          } else {
            data.push(0)
            categories.push('')
            this.chartOptions.xaxis.categories.push('')
          }
        }
      }
      this.chartOptions.xaxis.categories = categories
      this.series = [{ data: data }]
    }
  }
}
</script>

<style lang="scss" scoped>
.status-info{
    min-width: 70px;
}
.info-container{
  height: 175px;
}
.warning-card{
  color: white;
  background: var(--featured);
}
.card-info{
  padding: 20px 17px;
  box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.1) !important;
  height: 128px;
  .text-header{
    font-weight: 800;
    // padding: 12px 0 0;
    border-radius: 10px;
    font-size: 14.5px;
    float: left;
  }

  i{
    color: var(--featured);
    font-size: 25px;
    height: 25px;
    width: 25px !important;
    vertical-align: middle;
    item-align: center;
    line-height: 40px;
  }

  .btn{
    padding: .55rem 1.1rem !important;
    font-size: 13px;
  }

  .emissions{
    font-weight: 800;
    font-size: 17px;
  }

  .price{
    font-weight: 500;
    font-size: 14px;
    color: var(--featured);
  }

  .subtitle{
    font-weight: 500;
    font-size: 13px;
    color: var(--featured);
    margin-bottom: 10px;
  }
}
.progress-bar{
  background: var(--featured);
}
.table-card{
  height: 60vh !important;
  overflow: hidden auto;
  &::-webkit-scrollbar {
    display: none !important;
  }
  h4{
    font-weight: 800;
  }
}
.table{
    background-color: white;
    width: 100%;
    margin-top: 5px;
    border-radius: 10px;
    padding: 5px;
    box-shadow: none;
    th{
        border-top: none !important;
        padding-top: 15px !important;
        border-bottom: none;
        font-weight: 700;
        font-size: 12px;
    }
    td{
        font-size: 14px;
        vertical-align: middle;
        padding: 12px 14px !important;
    }
    .status{
        position: relative;
        margin: 0 auto;
        width: 10px;
        height: 10px;
        border-radius: 10px;
    }
    .status-info{
        margin-top: 5px;
        margin-bottom: 0;
        font-weight: 500;
        font-size: 10px;
        text-align: center;
    }
    .type{
        font-size: 14px;
        color: var(--featured);
        font-weight: 500;
    }
    .btn-secondary{
        padding: 0px !important;
    }
}
.search-container{
    background-color: white;
    box-shadow: none;
    padding: 0;
    padding-left: 12px;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    input{
      height: 40px !important;
      font-size: 13px;
    }
    .btn{
      font-size: 13px !important;
      padding: .70rem 0 !important;
      width: 100%;
    }

}
.title-loading{
  background: var(--green-light);
  height: 19px;
  width: 135px;
  border-radius: 3px;
  margin: 0px 15px 7px;
}
.subtitle-loading{
  background: var(--green-light);
  height: 18px;
  width: 100px;
  border-radius: 3px;
  margin: 3px 15px;
}
.btn-loading{
  background: var(--green-light);
  height: 35px;
  width: 160px;
  border-radius: 7px;
  margin: 10px 15px;
}
.card-chart{
  max-height: 60vh !important;
  padding: 30px 30px 30px 15px;
  h4{
    padding-left: 15px;
    font-weight: 800;
  }
}
.modal-header{
  border-bottom: none !important;
  button{
    opacity: 1 !important;
    padding-right: 0 !important;
  }
}
.modal-content{
  background-color: transparent !important;
  .row{
    padding: 0 !important;
  }
}

.player {
    border-radius: 10px;
    overflow: hidden;
    z-index: 1;
    height: 450px;
    width: 800px;
    transform: translate(18px, 0);
}
</style>
